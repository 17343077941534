<template>
  <b-card
    v-if="teamData !== undefined"
    class="profile-header mb-2"
    :img-src="randomHeaderImage"
    img-top
    alt="cover photo"
    body-class="p-0"
  >

    <!-- profile picture -->
    <div class="position-relative">
      <div class="profile-img-container d-flex align-items-center">
        <div
          class="profile-img"
          :style="`background-color: white; border: 0.357rem solid ${teamColorsCss[0]};`"
        >
          <b-img
            :src="teamData.logo"
            rounded
            alt="profile photo"
            style="padding: 7px; height: 7.1rem; overflow: hidden;"
          />
        </div>
        <!-- profile title -->
        <div class="profile-title ml-2">
          <h2
            class="text-white"
            style="text-shadow: black 1px 0 10px; -webkit-text-stroke-width: 0.2px; -webkit-text-stroke-color: black;"
          >
            {{ teamData.name }}
          </h2>
          <p
            class="text-white"
            style="text-shadow: black 1px 0 10px; -webkit-text-stroke-width: 0.1px; -webkit-text-stroke-color: black;"
          >
            {{ teamData.sport }} / {{ teamData.location }}
          </p>
        </div>
        <!--/ profile title -->
      </div>
    </div>
    <!--/ profile picture -->

    <!-- profile navbar -->
    <div class="profile-header-nav">
      <!-- <b-navbar
        toggleable="sm"
        type="light"
      > -->
      <b-navbar
        type="light"
      >
        <!-- toggle button -->
        <b-navbar-toggle
          class="ml-auto"
          target="nav-text-collapse"
        >
          <feather-icon
            icon="AlignJustifyIcon"
            size="21"
          />
        </b-navbar-toggle>
        <!--/ toggle button -->

        <!-- collapse -->
        <b-collapse
          id="nav-text-collapse"
          is-nav
        >
          <b-tabs
            pills
            class="profile-tabs mt-1 mt-md-0"
            nav-class="mb-0"
          >
            <template #tabs-start>
              <div
                v-if="teamData.rivalsdata !== undefined && teamData.rivalsdata.length > 0"
                class="font-weight-bold"
                style="padding: 1.0rem 0.5rem;"
              >
                Rivals:
              </div>
              <div
                v-else
                class="font-weight-bold"
                style="padding: 1.0rem 0.5rem;"
              />
              <router-link
                v-for="(rival, index) in teamData.rivalsdata"
                :key="`rival-${index}-X`"
                class="item"
                :to="`${rival.path}`"
              >
                <b-img
                  rounded="rounded"
                  :src="rival.logo"
                  blank-color="#ccc"
                  :alt="rival.name"
                  style="margin: 10px 5px; height: 30px; border: 1px solid gray; padding: 3px; background: white;"
                />
              </router-link>
              <!-- <b-nav-item
                role="presentation"
                class="font-weight-bold"
                :active="selectedTab === 'pics'"
                @click="selectTab('pics')"
              >
                <span class="d-none d-md-block">Feed</span>
                <feather-icon
                  icon="GridIcon"
                  class="d-block d-md-none"
                />
              </b-nav-item> -->

              <!-- <b-nav-item
                role="presentation"
                class="font-weight-bold"
                :active="selectedTab === 'list'"
                @click="selectTab('list')"
              >
                <span class="d-none d-md-block">Info</span>
                <feather-icon
                  icon="ListIcon"
                  class="d-block d-md-none"
                />
              </b-nav-item> -->

              <!-- <b-nav-item
                role="presentation"
                class="font-weight-bold"
                :active="selectedTab === null"
                @click="selectTab(null)"
              >
                <span class="d-none d-md-block">Other</span>
                <feather-icon
                  icon="ImageIcon"
                  class="d-block d-md-none"
                />
              </b-nav-item> -->
            </template>

            <!-- other buttons at end -->
            <!-- <template #tabs-end>
              <b-button
                variant="primary"
                class="ml-auto"
              >
                <feather-icon
                  icon="ShoppingCartIcon"
                  class="d-block d-md-none"
                />
                <span class="font-weight-bold d-none d-md-block">Shop</span>
              </b-button>
            </template> -->
            <!-- other buttons at end -->
          </b-tabs>

        </b-collapse>
        <!--/ collapse -->
      </b-navbar>
    </div>
    <!--/ profile navbar -->
  </b-card>
</template>

<script>
import {
  BCard,
  BImg,
  BNavbar,
  BNavbarToggle,
  BCollapse,
  BTabs,
  // BNavItem,
  // BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import {
  ref, computed, reactive, toRefs,
} from '@vue/composition-api'

export default {
  components: {
    // Card components
    BCard,
    BTabs,
    // BButton,
    // BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
  },

  directives: {
    Ripple,
  },

  props: {
    teamData: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, context) {
    const settings = reactive({
      selectedTab: 'pics',
    })

    const getBaseUrl = ref(context.root.$baseFrontendUrl)

    const randomHeaderImage = computed(() => {
      if (props && 'teamData' in props && props.teamData !== undefined && 'images' in props.teamData) {
        const randomId = Math.floor(Math.random() * props.teamData.images.length)
        return props.teamData.images[randomId]
      }
      return ''
    })

    const teamColorsCss = computed(() => {
      if (props && 'teamData' in props && props.teamData !== undefined && 'colors' in props.teamData) {
        return props.teamData.colors
      }
      return ['', '']
    })

    function selectTab(which) {
      settings.selectedTab = which
      context.emit('selectedTab', which)
    }

    return {
      ...toRefs(settings),
      getBaseUrl,
      randomHeaderImage,
      teamColorsCss,
      selectTab,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-profile.scss';
// Description: Page content different types of users page layouts SCSS.

@import '@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '@core/scss/base/components/include'; // Components includes

// User profile Scss
  /*-------------profile header section---------*/
  .profile-header {
    overflow: hidden;

    .card-img-top {
      width: 100%;
      height: 300px;
      object-fit: cover;
      object-position: center 20%;

      @media screen and (max-width: 1100px) {
        height: 300px;
      }

      @media screen and (max-width: 976px) {
        height: 300px;
      }

      @media screen and (max-width: 768px) {
        height: 200px;
      }

      @media screen and (max-width: 576px) {
        height: 200px;
      }

      @media screen and (max-width: 400px) {
        height: 200px;
      }
    }
    // profile picture container
    .profile-img-container {
      position: absolute;
      bottom: -2rem;
      left: 2.14rem;
      z-index: 2;

      // profile image
      .profile-img {
        height: 8.0rem;
        width: 10.0rem;
        border: 0.357rem solid $secondary;
        background-color: $secondary;
        border-radius: $card-border-radius;
        box-shadow: $box-shadow;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    // profile navbar padding
    .profile-header-nav {
      .navbar {
        padding: 0.8rem 1rem;

        // navbar toggle button
        .navbar-toggler {
          line-height: 0;
        }
        .profile-tabs {
          .nav-item {
            i,
            svg {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  /*-------- profile info section --------*/
  #profile-info {
    // profile star icons
    .profile-star {
      color: $gray-100;

      i,
      svg {
        // filled star icons
        &.profile-favorite {
          fill: $warning;
          stroke: $warning;
        }
      }
    }

    // filled heart icons
    .profile-likes {
      fill: $danger;
      stroke: $danger;
    }

    // progress-bar height
    .profile-polls-info {
      .progress {
        height: 0.42rem;
      }
    }
  }

  //profile-latest-img - hover effect
  .profile-latest-img {
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: translateY(-4px) scale(1.2);
      z-index: 10;
    }
    img {
      margin-top: 1.28rem;
    }
  }

  // Load More Button - Block UI
  .block-element {
    .spinner-border {
      border-width: 0.14rem;
    }
  }

// Latest Photo Section - Image size
@include media-breakpoint-down(md) {
  #user-profile {
    .profile-latest-img {
      img {
        width: 100%;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  // navbar tabs pills
  .profile-header-nav {
    .profile-tabs {
      width: 100%;
      margin-left: 13.2rem;
    }
  }
}

// profile img and title
@include media-breakpoint-down(xs) {
  #user-profile {
    .profile-header {
      .profile-img-container {
        .profile-img {
          height: 100px;
          width: 100px;
        }
        .profile-title {
          h2 {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

</style>
