<template>
  <div>
    <team-card
      v-show="team !== undefined"
      :header-data="team"
      @selectedTab="(tab) => selectedTab = tab"
    />

    <div style="display: inline-flex; flex-direction: row; flex-wrap: wrap; align-items: flex-start;">
      <h6
        v-if="divider !== ''"
        class="kf_hd2"
      >
        <span>
          <router-link
            class="nav-link"
            :to="`/${divider}`"
            style="color: white;"
          >
            <b-img
              :src="require(`@/assets/images/icons/sports/${image}.svg`)"
              height="30px"
              style="padding-right: 10px; padding-bottom: 5px;"
            />
            <strong class="titleClass">{{ divider }}</strong>
          </router-link>
        </span>

        <div class="more-link">
          <router-link
            class="nav-link"
            :to="`/${divider}`"
            style="color: white;"
          >
            {{ moreText }}
            <feather-icon
              icon="MoreHorizontalIcon"
              size="40"
            />
          </router-link>
        </div>
      </h6>

      <b-row
        no-gutters
        class="match-height col-sm-12 col-md-12 col-lg-12 col-xl-9 col-12"
        style="margin: 0px; padding: 0px; "
      >

        <h6
          v-if="title !== ''"
          class="kf_hd1"
        >
          <span><strong class="titleClass">{{ title }}</strong> {{ titleExtra }}</span>
        </h6>

        <!-- Adding 'sport' text to KEY id to avoid Vue warning for 'Duplicate Keys Detected' -->
        <b-col
          v-for="(post, index) in posts"
          :id="index"
          :key="`${uuid}-${sport}-${post.id}`"
          :class="(index === 0 || index === 3) ? 'col-sm-12 col-md-8 col-lg-8 col-12' : 'col-sm-6 col-md-4 col-lg-4 col-12'"
          :style="$store.getters['app/currentBreakPoint'] !== 'xs' ? 'padding-left: 5px; padding-right: 5px; margin-bottom: 10px;' : 'margin-bottom: 10px;'"
        >
          <tweet
            v-if="post.postType === 'tweet'"
            :key="post.id"
            :post="post"
          />
          <news
            v-else-if="post.postType === 'news'"
            :key="post.id"
            :post="post"
          />
          <youtube
            v-else-if="post.postType === 'youtube'"
            :key="post.id"
            :post="post"
          />
          <advertisement
            v-else-if="post.postType === 'ad'"
            :key="post.id"
            :post="post"
          />
          <template v-else>
            <post-carousel
              v-if="index == 7"
              :key="post.id"
              :posts="posts.slice(0, 11)"
              :tmp="index"
            />
            <post-wide
              v-else-if="index == 5"
              :key="post.id"
              :post="post"
              :tmp="index"
            />
            <post
              v-else
              :key="post.id"
              :post="post"
              :tmp="index"
            />
          </template>
        </b-col>
      </b-row>

      <div
        v-if="includeSide === 'true'"
        class="side-bar col-sm-12 col-md-4 col-lg-4 col-xl-3 col-12"
        style="padding-left: 5px; padding-right: 5px;"
      >

        <b-row>
          <b-col>
            <people-photos
              v-if="selectedTab === 'pics'"
              :people="people"
              :sport="sport"
            />
            <people-suggestions
              v-if="selectedTab === 'list'"
              :people="people"
              :sport="sport"
            />
            <top-list :posts="posts.slice(0, 5)" />
          </b-col>
        </b-row>

      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
} from 'bootstrap-vue'

import {
  ref, computed,
} from '@vue/composition-api'

import { v4 as uuidv4 } from 'uuid'

import store from '@/store'

import Post from '../posts/Post.vue'
import Tweet from '../posts/Tweet.vue'
import Youtube from '../posts/Youtube.vue'
import News from '../posts/News.vue'
import Advertisement from '../posts/Advertisement.vue'
import PostWide from '../posts/PostWide.vue'
import PostCarousel from '../posts/PostCarousel.vue'
import TeamCard from './TeamCard.vue'
import TopList from '../posts/TopList.vue'
import PeopleSuggestions from './PeopleSuggestions.vue'
import PeoplePhotos from './PeoplePhotos.vue'

export default {
  components: {
    // Card components
    BRow,
    BCol,
    BImg,

    // Post Types
    Post,
    Tweet,
    Youtube,
    News,
    Advertisement,
    PostWide,
    PostCarousel,
    TeamCard,
    TopList,
    PeopleSuggestions,
    PeoplePhotos,
  },

  props: {
    posts: {
      type: Array,
      default: () => [],
      // validator: (prop) => prop.every(e => typeof e === 'string'),
    },
    sport: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    titleExtra: {
      type: String,
      default: '',
    },
    includeSide: {
      type: String,
      default: 'true',
    },
    divider: {
      type: String,
      default: '',
    },
    moreText: {
      type: String,
      default: '',
    },
  },

  setup(props, context) {
    const uuid = ref(uuidv4())

    const getBaseUrl = ref(context.root.$baseFrontendUrl)

    const selectedTab = ref('pics')

    const handleClick = e => {
      let url = e.target.href

      if (url.endsWith('/')) {
        url = url.slice(0, url.length - 1)
      }

      if (e.button === 0) {
        window.open(url, '_blank')
      } else if (e.button === 1) {
        // Skip middle click, handled by browser.
      } else if (e.button === 2) {
        window.open(url, '_blank')
      }

      e.preventDefault()
    }

    const team = computed(() => {
      if (props.title === '') {
        return undefined
      }
      return store.getters['appData/getTeamFromSports'](props.sport, props.title)
    })

    const people = computed(() => {
      const data = store.getters['appData/getPeople']

      // return data.filter(person => (props.sport === '' || person.sport === props.sport))
      const sports = props.sport.split(',')
      return data.filter(person => (props.sport === '' || sports.indexOf(person.sport) > -1))
    })

    return {
      getBaseUrl,
      selectedTab,
      handleClick,
      people,
      team,
      uuid,
    }
  },
}
</script>

<style lang="scss" scoped>
.side-bar {
  // height: 100vh;
  margin: 0 0 0 0px;

  @media screen {
    width: 100%;
  }

  // @media screen and (max-width: 1444px) {
  //    width: 10px;
  // }
}

.bg {
  background-image: url("../../../src/assets/images/nav/soccer.png") !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.more-link {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: white;
}
</style>
