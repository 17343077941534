<template>
  <b-card
    no-body
    class="overflow-hidden rounded-0"
    style="margin-bottom: 0px; max-height: 600px;"
  >

    <template v-if="$can('update', 'Post')">
      <router-link
        v-if="$can('update', post)"
        class="nav-link"
        :to="`/authors/post/edit/${post.id}`"
        style="margin: 0px; padding: 0px;"
      >
        <a class="mc-btn-action">
          <i class="fa fa-pencil" />
        </a>
      </router-link>
      <a
        v-else
        class="mc-btn-action"
      >
        <i class="fa fa-lock" />
      </a>
    </template>

    <b-row
      no-gutters
      class="post-row"
    >

      <b-col
        v-if="imageExists"
        :class="imageClass"
      >
        <b-card-img
          v-image-fall-back
          v-b-toggle="'collapse-' + post.slug"
          :src="imageUrl"
          alt="Image"
          class="image-tmp rounded-0 page-link-local"
        />
      </b-col>

      <b-col
        :class="postClass"
      >
        <h4 class="post-category">
          <span
            v-for="(category, index) in post.categories"
            :key="index"
          >
            <a
              :href="category"
              class="text-muted"
              style="margin-right: 5px;"
            >
              {{ category }}
            </a>
          </span>
        </h4>

        <b-card-body style="padding: 10px 15px 15px 15px;">
          <b-card-text
            v-b-toggle="'collapse-' + post.slug"
            class="post-title page-link-local"
          >
            {{ post.title }} [{{ post.clicks }}]
          </b-card-text>

          <b-collapse
            :id="`collapse-${post.slug}`"
            :visible="$store.getters['app/currentBreakPoint'] !== 'xs' && $store.getters['app/currentBreakPoint'] !== 'sm' && $store.getters['app/currentBreakPoint'] !== 'md'"
            class="mt-1"
          >
            <b-card-text class="post-description">
              {{ post.description }}

              <Shares
                :post="post"
                :initially-active="true"
              />
            </b-card-text>
          </b-collapse>

          <b-card-text
            v-if="post.author !== null && post.author !== undefined"
            class="post-author small text-muted"
          >
            By
            <a
              :href="`${getBaseUrl}/authors/${post.author.username}`"
              class="text-muted"
            >
              {{ post.author.name }}
            </a>
          </b-card-text>
          <b-card-text class="post-time small text-muted">
            {{ formatDate(new Date(post.pubDate)) }}
          </b-card-text>
        </b-card-body>
      </b-col>

    </b-row>
  </b-card>
</template>

<script>
import {
  reactive, toRefs, computed,
} from '@vue/composition-api'

import {
  BCard,
  BCardText,
  BCardBody,
  BCardImg,
  BRow,
  BCol,
  BCollapse,

  VBToggle,
} from 'bootstrap-vue'

import moment from 'moment'

import Ripple from 'vue-ripple-directive'
import Shares from './Shares.vue'

export default {
  components: {
    // Card components
    BCard,
    BCardText,
    BCardBody,
    BCardImg,
    BRow,
    BCol,
    BCollapse,

    Shares,
  },

  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },

  props: {
    post: {
      type: Object,
      default: null,
    },
    minimal: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    const event = reactive({
      getBaseUrl: context.root.$baseFrontendUrl,
      imageError: false,
    })

    const formatDate = dt => {
      if (dt) {
        // return '17 minutes ago'
        // return moment(dt, 'HH:MM:SS').format('MM/DD/YYYY hh:mm')
        const a = moment(dt)
        const b = moment(new Date())
        const minutes = b.diff(a, 'minutes')
        if (minutes > 60) {
          if (minutes > 24 * 60) {
            if (minutes > 3 * 24 * 60) {
              return a.format('MM/DD/YYYY hh:mm')
            }
            const val = Math.round(minutes / (24 * 60))
            return `${val} days ago`
          }
          const val = Math.round(minutes / 60)
          return `${val} hours ago`
        }

        return `${minutes} minutes ago`
      }
      return ''
    }

    const handleClick = e => {
      let url = e.target.href

      if (url.endsWith('/')) {
        url = url.slice(0, url.length - 1)
      }

      if (e.button === 0) {
        window.open(url, '_blank')
      } else if (e.button === 1) {
        // Skip middle click, handled by browser.
      } else if (e.button === 2) {
        window.open(url, '_blank')
      }

      e.preventDefault()
    }

    const imageUrl = computed(() => props.post.images[0])

    const imageExists = computed(() => {
      if (Object.keys(props.post).length === 0) {
        return false
      }
      return props.post.images.length > 0 && props.post.images[0] != null && props.post.images[0] !== ''
    })

    const imageClass = computed(() => {
      if (props.minimal) {
        return 'col-lg-4 col-md-4 col-sm-4 col-4'
      }
      return 'col-lg-4 col-md-4 col-sm-12 col-12'
    })

    const postClass = computed(() => {
      if (props.minimal) {
        return 'col-lg-8 col-md-8 col-sm-8 col-8'
      }
      if (imageExists) {
        return 'col-lg-8 col-md-8 col-sm-12 col-12'
      }
      return 'col-lg-12 col-md-12 col-sm-12 col-12'
    })

    // :class="(post.images.length > 0 && post.images[0] != null && post.images[0] != '') ? 'col-sm-8 col-md-8 col-lg-8 col-8' : 'col-sm-12 col-md-12 col-lg-12 col-12'"

    return {
      // getBaseUrl,
      ...toRefs(event),
      formatDate,
      handleClick,
      imageUrl,
      imageExists,
      imageClass,
      postClass,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables';

$border: #ebebeb;
$text: #444;
$catpad: 15px;

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

.page-link-local, .page-link-local:visited, .page-link-local:hover, .page-link-local:active, .page-link-local:focus {
  outline: none !important;
}

.image-tmp {
  height: 100%;
}

.post-category {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.0625rem;
  margin: $catpad 0.0rem 0.0rem $catpad;

  @media screen and (max-width: 992px) {
    font-size: 0.7em;
    margin: 10px 0 0 $catpad;
  }

  @media screen and (max-width: 568px) {
    font-size: 0.7em;
  }
}

.post-title {
  @include transition(color 0.3s ease);
  font-size: 1.4rem;
  line-height: 1.25;
  font-weight: 500;
  overflow: hidden;
  color: $primary;

  @media screen {
    height: 100%;
  }

  @media screen and (max-width: 992px) {
    font-size: 1.2em;
  }

  @media screen and (max-width: 568px) {
    font-size: 0.9em;
  }
}

.post-description {
  overflow-wrap: anywhere;
  @include transition(color 0.3s ease);
  font-size: 1.2rem;
  line-height: 1.15;
  color: $text;
  font-weight: 400;
  width: 90wv;
  padding: 0 0 10px 0;

  @media screen and (max-width: 992px) {
    font-size: 1.0em;
  }

  @media screen and (max-width: 568px) {
    font-size: 0.9em;
  }
}

.post-author {
  line-height: 1;
  z-index: 10;
  text-align: left;
  // position: sticky;
  // padding: 10px 0 0 0;
  position: absolute;
  bottom: -5px;
  left: 10px;
}

.post-time {
  line-height: 1;
  z-index: 10;
  text-align: right;
  // position: sticky;
  // padding: 10px 0 0 0;
  position: absolute;
  bottom: 10px;
  right: 10px;
 }

.post-row {
  // Fixes the compressed view where the images don't fit the
  // height of the card. If applied to all sizes, even when image
  // is above the text, then the image sizes will be different for
  // different cards.

  // Removed the limit to screen width that we had on regular Posts
  // @media screen and (max-width: 992px) {
  min-height: 100%;
  // }
}

.post-shares {
  text-align: center;
  padding: 5px 0 5px 0;
}

.mc-btn-action {
  position: absolute;
  right: 8px;
  top: 8px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid;
  width: 36px;
  height: 36px;
  line-height: 33px;
  text-align: center;
  background-color: $primary;
  color: $secondary;
  cursor: pointer;
  z-index: 20;
  //.mc-transtition();
}

</style>
