<template>
  <div>

    <b-img
      :src="require('@/config/images/ads/banner.png')"
      class="ad"
    />

    <div
      v-if="data.search !== '' && data.posts.length > 0"
      class="alert success"
      style="margin: 20px 0 20px 0;"
    >
      <strong>{{ data.total }} POSTS WERE FOUND FOR YOUR
        "<span class="text-success">{{ data.search }}</span>" SEARCH
        IN: {{ data.time / 1000.0 }} SECONDS.
      </strong>
    </div>
    <div
      v-else-if="data.search !== '' && data.posts.length === 0"
      class="alert failure"
      style="margin: 20px 0 20px 0;"
    >
      <strong>NOTHING FOUND FOR
        "<span class="text-failure">{{ data.search }}</span>"
      </strong>
    </div>

    <view-composite
      :posts="data.posts"
      sport="nhl,nba"
      style="margin-bottom: 20px;"
    />

    <div class="center">
      <b-pagination-nav
        v-model="data.currentPage"
        style="border-radius: 0;"
        size="lg"
        :number-of-pages="data.pages"
        :link-gen="linkGen"
        use-router
      />
    </div>

  </div>
</template>

<script>
import {
  BImg,
  BPaginationNav,
} from 'bootstrap-vue'

import {
  reactive, computed, onMounted, watch,
} from '@vue/composition-api'

import { subject as an } from '@casl/ability'
// import store from '@/store'
import ViewComposite from '../sports/ViewComposite.vue'
import * as PostService from '../../services/PostService'

export default {
  components: {
    BImg,
    BPaginationNav,

    ViewComposite,
  },

  setup(props, context) {
    // let searchLoaded = true
    const data = reactive({
      search: '',
      posts: [],
      time: 0,
      pages: 1,
      currentPage: 1,
    })

    // const category = computed(() => {
    //   console.log('######### COMPUTED #####################')
    //   console.log(context.root.$route)
    //   return context.root.$route.meta.pageTitle.toLowerCase()
    // })

    function linkGen(pageNum) {
      return pageNum === 1 ? `?keywords=${data.search}` : `?keywords=${data.search}&page=${pageNum}`
    }

    // function get(sport) {
    //   console.log('====================== get =================================')
    //   if (store.state.app.searchQueryText !== '') {
    //     console.log(`called PostService.getAllPostsFromCategory(${sport}) + Search: ${store.state.app.searchQueryText}....results`)
    //     // return PostService.getAllPostsWithSearch(store.state.app.searchQueryText)
    //     return PostService.getAllPostsFromCategoryWithSearch(category.value, store.state.app.searchQueryText)
    //       .then(response => {
    //         // Have to Map the data using the CASL ability 'an' function to 'Post' items.
    //         data.posts = response.data.data.data.map(item => an('Post', item))
    //         // console.log(`\n---------------POSTS for Search ${store.state.app.searchQueryText}-----------------------------------------------`)
    //         // console.log(data.posts.length)
    //         data.search = store.state.app.searchQueryText
    //         store.state.app.searchQueryText = ''
    //         // searchLoaded = true

    //         data.time = response.data.time
    //         data.count = response.data.count
    //       })
    //     // .catch(error => {
    //     // console.log('\n---------------ERROR-----------------------------------------------')
    //     // console.log(error)
    //     // })
    //   }
    //   return PostService.getAllPostsFromCategory(sport)
    //     .then(response => {
    //       // console.log(`called PostService.getAllPostsFromCategory(${sport})....results`)
    //       // Have to Map the data using the CASL ability 'an' function to 'Post' items.
    //       data.posts = response.data.data.data.map(item => an('Post', item))
    //       // console.log(`\n---------------POSTS for Category ${sport}-----------------------------------------------`)
    //       // console.log(data.posts.length)
    //       store.state.app.searchQueryText = ''
    //       data.search = ''
    //       // searchLoaded = false

    //       data.time = response.data.time
    //       data.count = response.data.count
    //     })
    //     // .catch(error => {
    //     //   console.log('\n---------------ERROR-----------------------------------------------')
    //     //   console.log(error)
    //     // })
    // }

    // const getPosts = async (origin, sport, search) => {
    //   console.log(`\n -- async getPosts - [${origin}, ${sport}, ${search}] ------------------------------`)
    //   console.log(`KeyWords: ${context.root.$route.meta.keywords}`)

    //   store.getters.isLoading = true
    //   // console.log(cat !== '')
    //   // eslint-disable-next-line no-console

    //   // console.log(`Query Page: ${context.root.$route.query.page}`)

    //   if (search !== '') {
    //     await get(sport)
    //   } else {
    //     const result = store.getters['appData/getPostsBySport'](sport, data.pageNum)
    //     data.posts = result.posts
    //   }

    //   store.getters.isLoading = false
    // }

    // const isUpdated = computed(() => {
    //   console.log(`\n -- COMPUTED - [${store.state.app.searchQueryText}] ------------------------------`)
    //   if (store.state.app.searchQueryText !== '') {
    //     getPosts('computed', '', store.state.app.searchQueryText)

    //     return true
    //   }
    //   return false
    // })

    // const id = computed(() => context.root.$route.meta.pageTitle)

    // watch(id, newVal => {
    //   // console.log(newVal)
    //   console.log(`---------------------->>>>> watch ${newVal} ${data.currentPage} <<<<<---------------------`)
    //   // store.dispatch('appData/fetchPostsFromDatabase')
    //   // const page = context.root.$route.query.page || 1
    //   const page = 1
    //   data.currentPage = 1
    //   store.dispatch('appData/fetchPostsFromDatabaseForCategory', { category: newVal.toLowerCase(), page })
    // }, {
    //   immediate: true,
    // })

    // const vuexSearchText = computed(() => {
    //   console.log(`computed: ${store.state.app.searchQueryText}`)
    //   console.log(store.state.app)
    //   return store.state.app.searchQueryText
    // })
    // watch(vuexSearchText, newSearchText => {
    //   console.log(`--- watch vuexSearchText = ${vuexSearchText} -> ${newSearchText}`)
    //   store.dispatch('appData/fetchPostsForSearch', { text: newSearchText })
    // }, {
    //   immediate: true,
    // })

    // // const page = computed(() => context.root.$route.query.page || 1)

    // // watch(page, newVal => {
    // //   // console.log(newVal)
    // //   console.log(`---XXXX------------->>>>> watch ${newVal} <<<<<---------------------`)
    // //   store.dispatch('appData/fetchPostsFromDatabaseForCategory', { category: context.root.$route.meta.pageTitle.toLowerCase(), page: newVal })
    // // }, {
    // //   immediate: true,
    // // })

    // let thePosts = computed(() => {
    //   // const results = store.getters['appData/getPostsForCurrentPageXX']
    //   const results = store.getters['appData/getPostsForSearch']

    //   console.log('==============THE POSTS-===============')
    //   console.log(results)

    //   if (results === undefined || results.length === 0) {
    //     return {
    //       posts: [],
    //       count: 0,
    //       time: 0,
    //     }
    //   }

    //   if (results.count > 0) {
    //     data.pages = Math.ceil(results.count / 50.0)
    //   } else {
    //     data.pages = 1
    //   }
    //   return results
    // })

    // watch(thePosts, newPosts => {
    //   console.log('===================== watch thePosts ===========================')
    //   console.log(thePosts)
    //   console.log(newPosts)
    //   thePosts = newPosts
    // }, {
    //   immediate: true,
    // })

    // onMounted(getAllPosts)

    const keywords = computed(() => context.root.$route.query.keywords || '')
    const page = computed(() => context.root.$route.query.page || 1)
    const cat = computed(() => context.root.$route.query.cat || '')

    const getSearchResult = (text, pagenum) => {
      // console.log(`\n\n--------------------getSearchResult-[${text}, ${pagenum}]-----------------------------------`)
      if (text !== undefined && text !== '') {
        PostService.getAllPostsWithSearch(text, pagenum)
          .then(response => {
            // console.log('\n---- getAllPostsWithSearch -> post ---------------------------------')
            // console.log(response.data)
            // Have to Map the data using the CASL ability 'an' function to 'Post' items.
            data.search = text
            data.posts = response.data.posts.map(item => an('Post', item))
            data.time = response.data.time
            data.count = response.data.count
            data.total = response.data.total
            data.limit = response.data.limit
            data.currentPage = pagenum

            if (data.total > 0) {
              data.pages = Math.ceil(data.total / data.limit)
            } else {
              data.pages = 1
            }
          })
          // .catch(error => {
          //   console.log('\n---- getAllPostsWithSearch -> error --------------------------------')
          //   console.log(error)
          // })
      }
    }

    watch(keywords, newkeywords => {
      if (data.search !== newkeywords) {
        getSearchResult(newkeywords, 1)
      }
    }, {
      immediate: true,
    })

    watch(page, () => {
      if (data.currentPage !== page.value) {
        getSearchResult(data.search, page.value)
      }
    }, {
      immediate: true,
    })

    onMounted(() => {
      if (keywords !== undefined && keywords.value !== '') {
        getSearchResult(keywords.value, page.value)
      }
    })

    // onMounted(getSearchResult(keywords.value))

    return {
      data,
      linkGen,
      cat,
      // category,
      // isUpdated,
      // thePosts,
      // getAllPosts,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables';

.alert {
  position: relative;
  padding: 1.15rem 1.15rem;
  padding-left: 90px;
  margin-bottom: 1rem;
  background-color: #fff;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.alert::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 4px 0 0 4px;
  width: 70px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.success::before {
  background-color: $secondary;
  background-size: 28px 25px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 12'%3E%3Cpath transform='translate(-189.438 -2446.25)' fill='%23fff' d='M201.45,2446.24l2.121,2.13-9.192,9.19-2.122-2.12Zm-4.949,9.2-2.121,2.12-4.95-4.95,2.121-2.12Z'/%3E%3C/svg%3E");
}

.failure::before {
  background-color: $danger;
  background-size: 28px 25px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 18'%3E%3Cpath transform='translate(-194 -2257)' fill='%23fff' d='M194.714,2257h3.572a0.716,0.716,0,0,1,.714.72l-0.714,11.16a0.716,0.716,0,0,1-.715.72h-2.142a0.716,0.716,0,0,1-.715-0.72L194,2257.72A0.716,0.716,0,0,1,194.714,2257Zm0.715,14.4h2.142a0.716,0.716,0,0,1,.715.72v2.16a0.716,0.716,0,0,1-.715.72h-2.142a0.716,0.716,0,0,1-.715-0.72v-2.16A0.716,0.716,0,0,1,195.429,2271.4Z'/%3E%3C/svg%3E");
}

.text-success {
  color: $secondary !important;
}

.text-failure {
  color: $danger !important;
}

.ad {
  width: 100%;
  height: auto;
}

.center {
  display: flex;
  justify-content: center;
}
</style>
