<template>
  <div class="card__share">

    <!-- <span
      :class="isActive ? 'card__social--active' : 'card__social'"
      style="display: inline-block; text-align: center; width: 80px;"
    >
      <facebook
        style="padding:3px;"
        :url="getGoLink(post)"
        scale="1.4"
      />
      <twitter
        style="padding:3px;"
        :url="getGoLink(post)"
        scale="1.4"
        title="Check me on Github"
      />
      <email
        style="padding:3px;"
        :url="getGoLink(post)"
        scale="1.4"
        subject="Hello"
      />
    </span> -->
    <!--
      // Hiding the show/hide button - don't really need it anymore since always showing
      // the social links and the 'more' button.
    <a
      id="share"
      class="share-toggle share-icon"
      :class="isActive ? 'share-expanded' : ''"
      style="z-index: 5;"
      @click.prevent="shareClick"
    />
    -->
    <span
      :class="isActive ? 'card__social--active' : 'card__social_right'"
      style="text-align: left; display: inline-block; width: 80px;"
    >
      <!-- <b-button
        v-if="post.link !== ''"
        :id="post.id"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        :href="getGoLink(post)"
        variant="primary"
        size="sm"
        style="margin: 0 0 0 5px;"
        target="_blank"
        @click.prevent="handleClick"
        @click.middle.prevent="handleClick"
      >
        more
      </b-button> -->
      <b-button
        v-if="post !== undefined && post.slug !== undefined"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        :to="{ name: 'post-detail-slug',
               path: `/post/${post.slug}`,
               params: { slug: post.slug, post: JSON.stringify(post) }}"
        variant="primary"
        size="sm"
        style="margin: 0 0 0 5px;"
      >
        more
      </b-button>
      <!-- <router-link
        v-if="post !== undefined && post.slug !== undefined"
        class="nav-link"
        :to="{ name: 'post-detail-slug',
                path: `/post/${post.slug}`,
                params: { slug: post.slug, post }}"
        style="margin: 0px; padding: 0px;"
      >
        <a class="mc-btn-action mc-btn-unlocked">
          <i class="fa fa-pencil" />
        </a>
      </router-link> -->

      <b-button
        v-if="post.link !== ''"
        :id="post.id"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        :href="getGoLink(post)"
        variant="primary"
        size="sm"
        style="margin: 0 0 0 5px;"
        target="_blank"
        @click.prevent="handleClick"
        @click.middle.prevent="handleClick"
      >
        link
      </b-button>

      <!-- <b-button
        v-else
        variant="secondary"
        size="sm"
        style="margin: 0 0 0 5px;"
      >
        no link
      </b-button> -->
    </span>
  </div>
</template>

<script>
import {
  reactive, toRefs,
} from '@vue/composition-api'

import {
  BButton,

  VBToggle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

// import {
//   Facebook,
//   Twitter,
//   Email,
// } from 'vue-socialmedia-share'

export default {
  components: {
    // Card components
    BButton,

    // Social components
    // Facebook,
    // Twitter,
    // Email,
  },

  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },

  props: {
    post: {
      type: Object,
      default: null,
    },
    initiallyActive: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    const event = reactive({
      getBaseUrl: context.root.$baseFrontendUrl,
      isActive: props.initiallyActive,
      goLink: '',
    })

    const handleClick = e => {
      let url = e.target.href

      if (url.endsWith('/')) {
        url = url.slice(0, url.length - 1)
      }

      if (e.button === 0) {
        window.open(url, '_blank')
      } else if (e.button === 1) {
        // Skip middle click, handled by browser.
      } else if (e.button === 2) {
        window.open(url, '_blank')
      }

      e.preventDefault()
    }

    function shareClick() {
      event.isActive = !event.isActive
    }

    function getGoLink(post) {
      if (event.goLink !== '') {
        return event.goLink
      }
      if (post === undefined) {
        return ''
      }
      let goPath = `${event.getBaseUrl}/go`
      if (post.sport !== undefined && post.sport !== '') {
        goPath += `/${post.sport}`
      }
      if (post.team !== undefined && post.team !== '') {
        // Remove white space in team names: 'red sox' -> 'redsox'
        goPath += `/${post.team.replace(/\s/g, '')}`
      }
      goPath += `/${post.slug}`

      event.goLink = goPath

      return goPath
    }

    return {
      ...toRefs(event),
      handleClick,
      shareClick,
      getGoLink,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables';

.card__share {
  float: center;
  position: relative;
  margin-top: 0px;
  flex: none;
  text-align:center;
}

.card__social {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  visibility: hidden;
  width: 144px;
  transform: translateZ(0px);
  transform: translateX(36px);
  transition: transform 0.35s ease;
  flex: none;
}

.card__social_right {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  visibility: hidden;
  width: 144px;
  transform: translateZ(0px);
  transform: translateX(-36px);
  transition: transform 0.35s ease;
  flex: none;
}

.card__social--active {
  visibility: visible;
  z-index: 1;
  transform: translateZ(0px);
  transform: translateX(0px);
  transition: transform 0.35s ease;
}

.share-toggle {
  z-index: 2;
}

.share-toggle:before {
  content: "\f1e0";
  font-family: 'FontAwesome';
  color: $primary;
}

.share-toggle.share-expanded:before {
  content: "\f00d";
}

.share-icon {
  display: inline-block;
  width: 33px;
  height: 33px;
  line-height: 30px;
  text-align: center;
  border-radius: 25%;
  background-color: #fff;
  transition: all 0.3s ease;
  outline: 0;
  margin: 10px;
  margin-left: 5px;
  margin-right: 5px;

  // box-shadow:
  //   0 2px 4px rgba(0,0,0, 0.12),
  //   0 2px 4px rgba(0,0,0, 0.24);
  border: 1px solid rgba(204, 204, 204, 0.671);
}

.share-icon:hover,
.share-icon:focus {
  box-shadow:
    0 3px 6px rgba(0,0,0, 0.12),
    0 3px 6px rgba(0,0,0, 0.24);

  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
</style>
