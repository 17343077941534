<template>
  <b-card id="user-profile">
    <h5 class="mb-0">
      People [{{ filteredPeople.length }}]
    </h5>
    <b-row id="profile-info">
      <!-- latest photo loop -->
      <b-col
        v-for="data in filteredPeople"
        :key="data.avatar"
        md="4"
        cols="4"
        class="profile-latest-img"
      >
        <b-link>
          <router-link
            class="nav-link"
            :to="`/person/${data.sport}/${data.path}`"
            style="color: white;"
          >
            <b-img
              fluid
              rounded
              :src="data.avatar"
              :alt="data.avatar.slice(5)"
            />
          </router-link>
        </b-link>
      </b-col>
      <!-- latest photo loop -->
    </b-row>
  </b-card>
</template>

<script>
import {
  computed,
} from '@vue/composition-api'

import {
  BCard, BRow, BCol, BLink, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BImg,
  },

  props: {
    people: {
      type: Array,
      default: () => [],
    },
    sport: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const filteredPeople = computed(() => {
      if (props.sport !== '') {
        return props.people.filter(person => (person.name !== undefined) && (person.name !== '') && (person.sport === props.sport))
      }
      return props.people.filter(person => (person.name !== undefined) && (person.name !== ''))
    })

    return {
      filteredPeople,
    }
  },
}
</script>

<style lang="scss" scoped>
// @import '@core/scss/vue/pages/page-profile.scss';

@import '@core/scss/base/bootstrap-extended/_include'; // Bootstrap includes
@import '@core/scss/base/components/_include'; // Components includes

// User profile Scss
#user-profile {
  /*-------------profile header section---------*/
  .profile-header {
    overflow: hidden;

    // profile picture container
    .profile-img-container {
      position: absolute;
      bottom: -2rem;
      left: 2.14rem;
      z-index: 2;

      // profile image
      .profile-img {
        height: 8.92rem;
        width: 8.92rem;
        border: 0.357rem solid $white;
        background-color: $white;
        border-radius: $card-border-radius;
        box-shadow: $box-shadow;
      }
    }

    // profile navbar padding
    .profile-header-nav {
      .navbar {
        padding: 0.8rem 1rem;

        // navbar toggle button
        .navbar-toggler {
          line-height: 0;
        }
        .profile-tabs {
          .nav-item {
            i,
            svg {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  /*-------- profile info section --------*/
  #profile-info {
    // profile star icons
    .profile-star {
      color: $gray-100;

      i,
      svg {
        // filled star icons
        &.profile-favorite {
          fill: $warning;
          stroke: $warning;
        }
      }
    }

    // filled heart icons
    .profile-likes {
      fill: $danger;
      stroke: $danger;
    }

    // progress-bar height
    .profile-polls-info {
      .progress {
        height: 0.42rem;
      }
    }
  }

  //profile-latest-img - hover effect
  .profile-latest-img {
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: translateY(-4px) scale(1.2);
      z-index: 10;
    }
    img {
      margin-top: 1.28rem;
      height: 6rem;
      width: 6rem;
    }
  }

  // Load More Button - Block UI
  .block-element {
    .spinner-border {
      border-width: 0.14rem;
    }
  }
}
</style>
